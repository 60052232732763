// General
import { graphql, navigate } from 'gatsby';
import { useCallback, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import sanitizeHtml from 'sanitize-html';

// UI-kit
import Button from 'ui-kit/button/button';
import Link from 'ui-kit/link/link';
import ToastBox from 'ui-kit/toast-box/toast-box';

// Components
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent, { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';

import { accountResendVerificationEmailRoutine } from 'state/account/account.routines';
import { accountRegistrationFormValuesSelector, accountSignInUsernameSelector } from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { joinMembershipPlanInviteListSelector } from 'state/join-membership-plan/join-membership-plan.selectors';
// States
import { membershipRegistrationFormValuesSelector } from 'state/membership-registration/membership-registration.selectors';

// Hooks
import useMembershipRegistrationInit from 'hooks/useMembershipRegistrationInit';

// Styles
import './confirm-email.style.scss';

// Main component
const SuccessPage = ({ location, data }: { location: any; data: GatsbyTypes.InsuredDataQuery }) => {
    // General
    const params = new URLSearchParams(location.search);
    const flowParameter = params.get('flow') || undefined;
    const typeParameter = params.get('type');

    // Hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { onInitInsured, onInit } = useMembershipRegistrationInit();

    // States
    const [email, setEmail] = useState<string | undefined>();

    // Selectors
    const formValues = useSelector(membershipRegistrationFormValuesSelector);
    const inviteList = useSelector(joinMembershipPlanInviteListSelector);
    const signInUsername = useSelector(accountSignInUsernameSelector);
    const assistOthersFormValues = useSelector(accountRegistrationFormValuesSelector);

    const btnLabel =
        flowParameter === 'birdi-care' || flowParameter === 'mail-order' || flowParameter === 'assist-others'
            ? t('pages.confirmEmail.btnLabel.goHomepage')
            : t('pages.confirmEmail.btnLabel.returnSignIn');

    const eyebrowText =
        flowParameter === 'birdi-care' ? t('membership.success.weAreReady') : t('membership.success.oneLastStep');

    useEffect(() => {
        const hasValidUserInfo = onInit();
        const hasValidInfoInsured = onInitInsured();

        if (flowParameter === 'birdi-care' && typeParameter === 'join-membership-plan' && inviteList.length < 1) {
            navigate('/');
            return;
        } else if (!flowParameter && !hasValidUserInfo && !hasValidInfoInsured) {
            navigate('/get-started/membership');
            return;
        }
    }, [formValues, onInit, onInitInsured, flowParameter, inviteList, typeParameter]);

    useEffect(() => {
        let emailAddress: string | undefined;

        switch (flowParameter) {
            case 'birdi-care':
                if (typeParameter === 'join-membership-plan') {
                    emailAddress = inviteList[0]?.recipientEmailAddress;
                } else {
                    emailAddress = formValues.email;
                }
                break;
            case 'mail-order':
                emailAddress = formValues.username;
                break;
            case 'unverified-email':
                emailAddress = signInUsername;
                break;
            case 'assist-others':
                emailAddress = assistOthersFormValues?.username;
                break;
            default:
                break;
        }

        setEmail(emailAddress);
    }, [flowParameter, inviteList, formValues, typeParameter, signInUsername, assistOthersFormValues?.username]);

    const handleResendLink = useCallback(() => {
        dispatch(
            accountResendVerificationEmailRoutine.trigger({
                email: email,
                onSuccess: () => {
                    dispatch(
                        openModal({
                            showClose: false,
                            size: 'xl',
                            isModalCentered: true,
                            onClose: () => {
                                dispatch(closeModal({}));
                            },
                            bodyContent: (
                                <BirdiModalContent
                                    icon={'default'}
                                    title={t('modals.updateProfile.title')}
                                    body={t('pages.confirmEmail.resendLink.success.message')}
                                />
                            ),
                            ctas: [
                                {
                                    dataGALocation: 'ResendVerificationLinkSuccess',
                                    label: t('registration.errors.modals.default.button'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    }
                                }
                            ]
                        })
                    );
                },
                onFailure: () => {
                    dispatch(
                        openModal({
                            showClose: false,
                            type: 'danger',
                            size: 'lg',
                            headerContent: (
                                <BirdiModalHeaderDanger
                                    headerText={t('registration.errors.modals.default.title')}
                                    icon="alert"
                                />
                            ),
                            bodyContent: (
                                <BirdiModalContentAlt
                                    subTitle={t('pages.confirmEmail.resendLink.error.message')}
                                    note={t('registration.errors.modals.default.note')}
                                />
                            ),
                            ctas: [
                                {
                                    dataGALocation: 'ResendVerificationLinkError',
                                    label: t('registration.errors.modals.default.button'),
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    },
                                    variant: 'primary'
                                }
                            ]
                        })
                    );
                }
            })
        );
    }, [dispatch, email, t]);

    const commonMessage = () => {
        return (
            <ToastBox variant="info" icon="info">
                <p
                    className="note"
                    dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(t('pages.confirmEmail.footNote'), { allowedTags: ['strong'] })
                    }}
                />
            </ToastBox>
        );
    };

    const renderContent = (flow: string | undefined) => {
        switch (flow) {
            case 'birdi-care':
                return (
                    <>
                        <p
                            className="subtitle1"
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(
                                    t('pages.confirmEmail.flow.birdiCare', { email: email }),
                                    { allowedTags: ['strong'] }
                                )
                            }}
                        />
                        {commonMessage()}
                    </>
                );
            case 'unverified-email':
                return (
                    <>
                        <p className="subtitle1">{t('pages.confirmEmail.flow.unverifiedEmail')}</p>
                        {commonMessage()}
                    </>
                );
            case 'mail-order':
                return (
                    <>
                        <p
                            className="subtitle1"
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(
                                    t('pages.confirmEmail.flow.mailOrder', { email: formValues.username }),
                                    { allowedTags: ['strong'] }
                                )
                            }}
                        />
                        {commonMessage()}
                    </>
                );
            case 'assist-others':
                return (
                    <>
                        <p
                            className="subtitle1"
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(
                                    t('pages.confirmEmail.flow.mailOrder', { email: assistOthersFormValues?.username }),
                                    { allowedTags: ['strong'] }
                                )
                            }}
                        />
                        {commonMessage()}
                    </>
                );
            default:
                return (
                    <p
                        className="subtitle1"
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(t('pages.linkExpired.subtitle'), { allowedTags: ['strong'] })
                        }}
                    />
                );
        }
    };

    return (
        <WorkflowLayout
            backgroundImage={data.backgroundImage}
            useRoundedCorners={false}
            metaData={{ nodeTitle: t('membership.success.profileTitle') }}
            anonymousOnly={true}
        >
            <WorkflowLayoutFormWrapper
                currentFlow={'membership-registration-header'}
                eyebrowText={eyebrowText}
                title={t('membership.success.validateYourEmail')}
            >
                <Container className="confirm-email">
                    {renderContent(flowParameter)}

                    <Link
                        to={'/sign-in'}
                        label={btnLabel}
                        variant="nav-button-primary"
                        dataGALocation="membership-registration-go-homepage"
                        ariaLabel="go-homepage"
                    />
                </Container>
            </WorkflowLayoutFormWrapper>

            <div className="resend-link-section">
                <p>{t('pages.confirmEmail.resendLink.description')}</p>
                <Button
                    label={t('pages.confirmEmail.resendLink.btnLabel')}
                    type={'button'}
                    variant="text-blue"
                    className="no-margin"
                    onClick={handleResendLink}
                />
            </div>
        </WorkflowLayout>
    );
};

export default SuccessPage;

export const query = graphql`
    query SuccessData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        backgroundImage: file(relativePath: { eq: "assets/images/white-feathers-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
